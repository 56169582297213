import axios from "axios";
axios.defaults.withCredentials = true;
// let apiUrl = "/api";
let apiUrl = "/api";

let auth = {
  me: ()=> axios.get(`${apiUrl}/auth/me`),
  login: (email, password)=> axios.post(`${apiUrl}/auth/login`, {email, password}),
  signup: (email, password, firstName, lastName)=> axios.post(`${apiUrl}/auth/login`, {email, password, firstName, lastName}),
  
  restaurant: ()=> axios.get(`${apiUrl}/auth/restaurant`),
  restaurantLogin: (email, password)=> axios.post(`${apiUrl}/auth/restaurantLogin`, {email, password}),
}

let covid = {
  submitContactInfo: (email, phoneNumber, firstName, lastName) => axios.post(`${apiUrl}/covid/submitContactInfo`, {email, phoneNumber, firstName, lastName}),
  getMyContactInfo: () => axios.get(`${apiUrl}/covid/getMyContactInfo`)
}


let restaurant = {
  getById: (id)=> axios.get(`${apiUrl}/restaurant/restaurants/${id}`),
  getAll: ()=> axios.get(`${apiUrl}/restaurant/restaurants/`),
}

let qrCode = {
  getById: (id) => axios.get(`${apiUrl}/qr-code/qr-codes/${id}`),
  setTableName: (id, tableName)=> axios.put(`${apiUrl}/qr-code/setTableName/${id}`, {tableName}),
  getByAlias: (alias) => axios.get(`${apiUrl}/qr-code/alias/${alias}`),
  getScannedRestaurant: () => axios.get(`${apiUrl}/qr-code/getScannedRestaurant`),
  setRestaurant: (id, restaurant) => axios.put(`${apiUrl}/qr-code/setRestaurant/${id}`, {restaurantId: restaurant._id})
}

let order = {
  getById: (id) => axios.get(`${apiUrl}/order/orders/${id}`),
  getMyOrders: () => axios.get(`${apiUrl}/order/myOrders/`),
  submitOrder: (order) => axios.post(`${apiUrl}/order/submit/`, {order}),
}

let kitchen = {
  getUpcomingOrders: ()=> axios.get(`${apiUrl}/kitchen/getUpcomingOrders/`),
  getOrderHistory: ()=> axios.get(`${apiUrl}/kitchen/getOrderHistory/`),
  
  restoreOrders: (orders) => axios.put(`${apiUrl}/kitchen/restoreOrders/`, {orders}),
  acceptOrders: (orders) => axios.put(`${apiUrl}/kitchen/acceptOrders/`, {orders}),
}

export default {auth, covid, restaurant, qrCode, order, kitchen};
