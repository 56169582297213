import React from "react";
import logo from '../assets/get-the-menu.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LogoutButton from "./LogoutButton";
import LoggedIn from "./LoggedIn";

export default function NavBar() {
  return [
    <AppBar elevation={3} position="sticky" style={{backgroundColor:"white"}}>
      <Toolbar>
        <a href="/" >
          <img src={logo} alt="Get The Menu" style={{maxHeight:"50px", paddingTop:"5px"}}/>
        </a>
        <div style={{flexGrow: 1,}}></div>
        <LoggedIn component={LogoutButton}></LoggedIn>
        {/* <LoggedIn component={NavMenu}></LoggedIn> */}
      </Toolbar>

    </AppBar>
  ];
}