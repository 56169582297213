import React, { useEffect } from "react";
import { Typography, Box, AppBar } from '@material-ui/core';
import { Switch, Route } from "react-router-dom";
import CovidContactPage from "./pages/CovidContactPage";
import MenuPage from "./pages/MenuPDF";
import api from "../api";
import LoadingPage from "./pages/LoadingPage";
import OldMenu from "../Menu";
import RestaurantFooter from "./components/RestaurantFooter";

export default function MenuPDF() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [restaurant, setRestaurant] = React.useState(null);
  const [usePDF, setUsePDF] = React.useState(false);

  useEffect(() => {

    // Your axios call here
    api.qrCode.getScannedRestaurant()
      .then(response => {
        console.log(response);
        // For success, update state like
        if (response.data) {
          console.log(response.data);
          setIsLoading(false);
          setRestaurant(response.data);
          setUsePDF(response.data.usePDF);
        }
        else {
          setIsLoading(false);
          setRestaurant(null);
        }
      })
      .catch(error => {
        console.log(error);
        setRestaurant(null);
        setIsLoading(false);
      });
  }, []);

  return (
    isLoading ? <LoadingPage /> :
      restaurant ?
        !usePDF ? <OldMenu /> :

          <Route path="/menu/">
            <Switch>

              <Route path="/menu/covid">
                <Box style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                  <Box style={{ flexGrow: 1, overflow: "none", display: "flex", flexDirection: "column"}}>
                    <CovidContactPage restaurant={restaurant.json} />                    
                  </Box>
                  <RestaurantFooter restaurant={restaurant} value={0} />
                </Box>
              </Route>

              <Route path="/menu/">
                <Box style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                  <Box style={{ flexGrow: 1, overflow: "none", paddingBottom:"40vh" }}>
                    <MenuPage restaurant={restaurant.json} pdfURL={restaurant.pdf} />
                  </Box>
                  <RestaurantFooter restaurant={restaurant} value={1} />
                </Box>
              </Route>

            </Switch>
          </Route>

        :

        <Typography>
          Scan a QR-Code to view a restaurant's menu
        </Typography>
  )
}
