import React from 'react';
import {
  // BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import CovidContactPage from './CovidContactPage';
import MenuPage from './MenuPage';
import MyOrdersPage from './MyOrdersPage';
import { RestaurantNavBar } from '../components/RestaurantNavBar';
import MenuItemPage from './MenuItemPage';

function cleanUrl(url) {
  return url.toLowerCase().split(" ").join("-");
}


export default function RestaurantPage({ restaurant }) {

  return (
    <Switch>

      <Route path="/menu/menus/:menuName/">
        {
          restaurant.menus.map(menu => {
            return (
              <Route key={`${menu.name}`} exact path={cleanUrl(`/menu/menus/${menu.name}`)}>
                <RestaurantNavBar key={`RestaurantNavBar:${menu.name}`} restaurant={restaurant} value={restaurant.menus.indexOf(menu) + 1} />
                <MenuPage key={`MenuPage:${menu.name}`} menu={menu} />
              </Route>
            )
          })
        }

        {
          restaurant.menus.map(menu => {
            return menu.categories.map((category) => {
              return category.menuItems.map(item => {
                return (
                  <Route key={"route-item:"+category.name+item.name} exact path={cleanUrl(`/menu/menus/${menu.name}/${category.name}/${item.name}`)}>
                    <MenuItemPage key={"menu-item:"+category.name+item.name} item={item} menuName={menu.name} restaurantName={restaurant.name} />
                  </Route>
                )
              })
            })
          })
        }
      </Route>

      <Route exact path="/menu/my-order">
        <RestaurantNavBar restaurant={restaurant} value={restaurant.menus.length + 1} />
        <MyOrdersPage />
      </Route>

      <Route path="/menu/covid">
        <RestaurantNavBar restaurant={restaurant} value={0} />
        <CovidContactPage />
      </Route>

      <Route path="/menu/">
        <RestaurantNavBar restaurant={restaurant} value={0} />
        <CovidContactPage />
      </Route>

    </Switch>
  );
}