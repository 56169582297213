import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    // height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
}));

export function CovidForm() {
  const classes = useStyles();
  return (
    <Card>
      <CardContent >
        <Box padding="15px">
          <Typography variant="h5" component="h3" >Covid Contact Info</Typography>
          <form className={classes.root} noValidate autoComplete="off">

            <Typography style={{ color: "#5D6B77" }}>
              Help improve covid-19 contract tracing
              to prevent outbreaks by submitting your
              Contact information.
            </Typography>

            <TextField
              id="name-input"
              label="Name"
              margin="normal"
              fullWidth
              placeholder="John Smith"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              variant="outlined"
              required={true}
              type="text"
            />

            <TextField
              id="email-input"
              label="Email"
              margin="normal"
              fullWidth
              placeholder="JohnSmith@gmail.com"

              InputLabelProps={{
                shrink: true,
              }}

              size="small"
              variant="outlined"
              type="email"
              required={true}
            />

            <TextField
              id="phone-input"
              label="PhoneNumber"
              fullWidth
              placeholder="519-555-5555"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              variant="outlined"
              type="cell"
              required={true}
            />

            <Button variant="contained" color="primary" style={{ marginTop: 15 }}>Submit</Button>

          </form>

        </Box>
      </CardContent>
    </Card>
  );
}
