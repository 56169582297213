import React from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { CovidForm } from '../../components/CovidForm';

export default function CovidContactPage() {
  return (
    <Container maxWidth="sm" height="100%">
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "20px", height: "100%" }}>
        <CovidForm />
      </Box>
    </Container>
  );
}