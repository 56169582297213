import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";

const useStyles = makeStyles((theme) => ({

  root: {
    padding: theme.spacing(3, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
}));


export default function RegisterPage() {
  const classes = useStyles();
  return [
    <NavBar />,
    <Container maxWidth="sm">
      <Box height="100vh" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Card m="auto">
          <CardContent>
            <Typography variant="h5" component="h3">Register</Typography>

            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="name-input"
                label="Name"
                style={{ margin: 8 }}
                fullWidth
                placeholder="John Smith"
                helperText="what should we call you?"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                variant="outlined"
                required="true"
                type="text"
              />

              <TextField
                id="email-input"
                label="Email"
                style={{ margin: 8 }}
                fullWidth
                placeholder="JohnSmith@gmail.com"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                variant="outlined"
                type="email"
                required="true"
              />

              <TextField
                id="password-input"
                label="Password"
                style={{ margin: 8 }}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                variant="outlined"
                type="password"
                required="true"
              />

              <Button component={Link} to="/dashboard" variant="contained" color="primary" style={{ marginTop: 15 }}>Register</Button>
              <Button component={Link} to="/login" color="primary">Already have an account? login!</Button>

            </form>
          </CardContent>
        </Card>
      </Box>
    </Container>
  ];
}

