import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button'
import api from '../../../api';

export default function AcceptOrderButton({ onAccepted = (newPendingOrders) => { }, selectedOrders = [] }) {
  const [isOnline, setIsOnline] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const [orders, setOrders] = useState(selectedOrders);

  useEffect(() => {
    //TODO: check if we need to kill this setInterval when component is destroyed.
    setInterval(() => { setIsOnline(navigator.onLine); }, 1000);
  }, []);

  function acceptOrder() {
    if (!(!isLoading && isOnline)) return;

    setIsLoading(true);
    api.kitchen.acceptOrders(selectedOrders)
      .then(response => {
        console.log("acceptOrders");
        console.log(response);
        onAccepted(response.data);
        // setOrders([]);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }


  return (

    <Button
      disabled={!isOnline || isLoading || !selectedOrders.length}
      onClick={acceptOrder}
      style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        backgroundColor: selectedOrders.length === 0 || !isOnline || isLoading ? 'gray' : '#9534F7',
        size: 'large'
      }}>

      <div style={{
        color: !isOnline ? 'orange' : selectedOrders.length === 0 ? '#cccccc' : 'white',
        fontSize: '27px',
        padding: '15px',
        paddingLeft: '35px',
        paddingRight: '35px',
      }}>

        {
          isLoading ? `Loading (${selectedOrders.length})` :
            isOnline ? `Accept Order (${selectedOrders.length})` : "No Internet Connection"
        }

      </div>
    </Button>

  );
}