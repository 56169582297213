import React from "react";
import { CssBaseline, Box } from '@material-ui/core';
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import RenameTable from "./pages/RenameTable";

import {
  Switch,
  Route,
} from "react-router-dom";

export default function OwnerTool() {
  console.log("OwnerTool/index.js");
  return (
    <Box>
      <CssBaseline />  
      <Switch>
        <Route path="/restaurant/login" component={Login}/>
        <ProtectedRoute path="/restaurant/register" component={Register} />
        <ProtectedRoute path="/restaurant/rename-table/:alias" component={RenameTable} />
        <ProtectedRoute path="/restaurant/" component={Dashboard} />
      </Switch>
    </Box>
  );
}
