import React, { useState, useLayoutEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Container from '@material-ui/core/Container';
// import Chip from '@material-ui/core/Chip';
import menu_pdf from '../pdfs/la_cucina.pdf';
// import useDimensions from "react-cool-dimensions";
import { AppBar, Box, Divider } from '@material-ui/core';
import RestaurantHeader from "../components/RestaurantHeader";
import RestaurantNavBar from "../components/RestaurantFooter";
import LoadingPage from './LoadingPage';
import SearchBar from "material-ui-search-bar";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default ({ restaurant, pdfURL }) => {
  const [numPages, setNumPages] = useState(null);
  const [width] = useWindowSize();
  const [searchValue, setSearchValue] = useState("");
  const [ref, setRef] = useState(null);
  /*
    TODO: wrap the Document component in a thing that lets us select different menus, 
    then we can pass the menu-pdf as a prop to this component to control which menu to display.
  */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setRef(React.createRef());
  }

  function getPages() {
    let pages = [];
    for (let page = 1; page <= numPages; page++) {
      pages.push((
        <Page
          key={`page-${page}`}
          pageNumber={page}
          width={width <= 960 ? width : 960}
          loading={LoadingPage}
        />
      ))
    }
    return pages;
  }

  function searchMenu() {
    //alert(searchValue);
    window.find(searchValue);

    var range = window.getSelection().getRangeAt(0);
    var dummy = document.createElement("span");

    range.insertNode(dummy);
    var box = dummy.getBoundingClientRect();
    // var x = box.x, y = box.y;
    dummy.scrollIntoView();
    dummy.parentNode.removeChild(dummy);

  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>

      <AppBar elevation={0} position="sticky" variant="fullWidth" style={{backgroundColor:"white"}}>
        <RestaurantHeader restaurant={restaurant} value={1} />

        <Divider />

        <SearchBar placeholder="Search the Menu..."
          value={searchValue}
          onChange={(newValue) => setSearchValue(newValue)}
          onRequestSearch={() => searchMenu()}
        />

      </AppBar>


      <Container style={{ padding: "0px", overflow: "scroll", flexGrow: 1,  }} maxWidth="md">
        <Document
          onRequestSearch
          // file={menu_pdf}
          // file={"/pdf/la-cucina/drinks.pdf"}
          // file={"/pdf/mc-cabes/patio.pdf"}
          file={pdfURL}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={error => alert(error)}
          style={{ zIndex: 2, position: "absolute" }}
          loading={LoadingPage}
          ref={ref}
        >
          {getPages()}
        </Document>
      </Container>

      {/* Todo: Find a better way to show chips above footer so footer doesn't cover chips on smaller phones,
      but chips overlay on pdf. */}

      {/* <RestaurantNavBar restaurant={restaurant} value={1} /> */}
    </Box>
  );
}