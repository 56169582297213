import React, { useState, useEffect } from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api';
import { FormControl, FormHelperText, InputLabel, NativeSelect } from '@material-ui/core';

const Styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  greyText: { color: "grey" },
  link: { color: "#9534F7", textDecoration:"none", fontWeight:400, fontSize:18, padding:"10px" },

}));


export default function SetTableName() {
  let [qrCode, setQRCode] = useState(null);
  let [selectedRestaurant, setSelectedRestaurant] = useState(null);
  let [currentRestaurant, setCurrentRestaurant] = useState(null);
  let [restaurants, setRestaurants] = useState(null);
  let [restaurantsMap, setRestaurantsMap] = useState(null);
  let [isLoading, setIsLoading] = useState(true);
  let [isButtonLoading, setIsButtonLoading] = useState(false);

  const classes = Styles();
  const { alias } = useParams();

  const onSelection = (e) => {
    console.log("SELECTED:", e.target.value);
    console.log(e.target.value);
    setSelectedRestaurant(restaurantsMap[e.target.value]);
  };

  const submit = (e) => {
    //Check to make sure QR code exists
    if (!qrCode) return;

    setIsButtonLoading(true);
    api.qrCode.setRestaurant(qrCode._id, selectedRestaurant)
      .then(response => {
        console.log(response);
        setCurrentRestaurant(selectedRestaurant);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsButtonLoading(false);
      })
  };

  // Init 
  useEffect(() => {
    let init = async ()=>{
      try {
        let qrResponse = await api.qrCode.getByAlias(alias);
        let restaurantResponse = await api.restaurant.getById(qrResponse.data.restaurantId);
        let restaurantsResponse = await api.restaurant.getAll();

        setQRCode(qrResponse.data);
        setCurrentRestaurant(restaurantResponse.data);
        setSelectedRestaurant(restaurantResponse.data);
        setRestaurants(restaurantsResponse.data);
        let restaurantMap = {}
        restaurantsResponse.data.forEach((r)=>restaurantMap[r.name]=r);        
        setRestaurantsMap(restaurantMap);

        setIsLoading(false);
      }
      catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    init();
    // api.qrCode.getByAlias(alias)
    //   .then(response => {
    //     console.log(response.data);
    //     // qrCode = response.data;
    //     setIsLoading(false);
    //     setQRCode(response.data);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     setIsLoading(false);
    //   });
  }, [alias])


  return (
    (isLoading) ? "Loading..." :
      !qrCode ? "This QR code doesn't exist!" :
        <Container maxWidth="sm" style={{ textAlign: 'center', minHeight: "100vh", display: "flex", flexDirection: "column" }} >
          <Box style={{ flexGrow: 1 }} />
          <Card>
            <CardContent >
              <Box padding="15px">

                <Typography variant="h5" component="h3" >Change QR Code's Restaurant</Typography>
                <br />
                <Typography className={classes.desc} >Current Restaurant: {currentRestaurant ? currentRestaurant.name : "Null"} </Typography>
                {console.log(currentRestaurant)}
                <form className={classes.root} noValidate >

                  {/* <TextField
                    label="Table Name"
                    margin="normal"
                    fullWidth
                    placeholder="Table Name"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="outlined"
                    type="email"
                    required={true}
                    onChange={onSelection}
                  /> */}
                  <FormControl className={classes.formControl}>
                    <NativeSelect value={selectedRestaurant ? selectedRestaurant.name : "None"} onChange={onSelection}>
                      {
                        restaurants?.map((restaurant)=>{
                          return (
                            <option value={restaurant.name}>{restaurant.name}</option>
                          )
                        })
                      }
                    </NativeSelect>
                    <FormHelperText>Select a restaurant to set</FormHelperText>
                  </FormControl>

                  <Button onClick={submit} disabled={isButtonLoading} variant="contained" color="primary" style={{ marginTop: 15 }}>
                    {isButtonLoading ? "Loading..." : "Set Restaurant"}
                  </Button>

                  <br /><br />

                  <Typography className={classes.desc} >alias: {alias}</Typography>
                  <Typography className={classes.desc} >tableName: {qrCode.tableName}</Typography>
                  <Typography className={classes.desc} >restaurant: {currentRestaurant?.name}</Typography>
                  <Typography className={classes.desc} >restaurantId: {qrCode.restaurantId}</Typography>


                </form>
                <br />

                <Box>
                <Link to={`/admin/rename-table/${alias}`} className={classes.link} > 
                  Rename Table
                </Link>                  <br /><br />
                  <Link to={`/menu/`} className={classes.link} >View Menu</Link>
                </Box>
                <br />
                {/* <Typography style={{ padding: "15px", color: "red" }}>{errorMessage}</Typography> */}
              </Box>
            </CardContent>
          </Card>
          <Box style={{ flexGrow: 1 }} />
        </Container>
  );
}