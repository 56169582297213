import React, {useState, useEffect} from 'react'
import api from "../../api";
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';


export default function LoggedIn({ component }) {

  let [isLoading, setIsLoading] = useState(true);
  let [isLoggedIn, setIsLoggedIn] = useState(false)
  const history = useHistory();

  useEffect(() => {

    history.listen((location, action) => {
      // location is an object like window.location
      console.log(action, location.pathname, location.state);
      api.auth.me()
        .then(response => {
          // For success, update state like
          if (response.data){
            // this.setState(() => ({ isLoading: false, isLoggedIn: true }));
            setIsLoading(false);
            setIsLoggedIn(true);
          }
          else {
            setIsLoading(false);
            setIsLoggedIn(false);
          }
        })
        .catch(error => {
          // For fail, update state like
          // this.setState(() => ({ isLoading: false, isLoggedIn: false }));
          setIsLoading(false);
          setIsLoggedIn(false);
        });
    });

    // Your axios call here
    api.auth.me()
      .then(response => {
        // For success, update state like
        if (response.data){
          // this.setState(() => ({ isLoading: false, isLoggedIn: true }));
          setIsLoading(false);
          setIsLoggedIn(true);
        }
        else {
          setIsLoading(false);
          setIsLoggedIn(false);
        }
      })
      .catch(error => {
        // For fail, update state like
        // this.setState(() => ({ isLoading: false, isLoggedIn: false }));
        setIsLoading(false);
        setIsLoggedIn(false);
      });
  }, [history]);

  return (
    isLoading ? "loading..." :
      isLoggedIn ?
        <Box component={component} /> :
        "not logged in"
  )
}