import React from 'react';
import Box from "@material-ui/core/Box";
import { AppBar, Toolbar, IconButton, Typography, Button, TextField, Container } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function MenuItemPage({ item, menuName, restaurantName = "La Cucina" }) {
  const [open, setOpen] = React.useState(false); // TODO: understand react component state.

  const [selectedPrice, setSelectedPrice] = React.useState(0);
  const priceRadioGroupRef = React.useRef(null);

  const handlePriceSelection = (event) => {
    console.log("value: " + event.target.value);
    setSelectedPrice(event.target.value);
    console.log("selectedPrice: " + selectedPrice);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
  };



  const styles = useStyles();
  return (
    // Todo: determine proper way to set AppBar color to white.
    <Container style={{padding:"0px"}}>
      <AppBar color="white">
        <Toolbar>
          <IconButton
            aria-label="back"
            component={Link}
            to={`/demo/restaurant/${restaurantName || "demo"}/menus/${menuName}`}
          > <ArrowBackIosIcon /> </IconButton>

          <Typography
            variant="h6"
            color="inherit"
            component={Link}
            to={`/demo/restaurant/${restaurantName || "demo"}/menus/${menuName}`}
            style={{ textDecoration: 'none' }}
          > {restaurantName} </Typography>

        </Toolbar>
      </AppBar>

      <Box width="100%" height="100vh">
        <Box width="100%" height="40%" style={{ backgroundImage: `url(${item.image})`, backgroundSize: "cover", display: "flex", backgroundColor: "#F1F2F4", direction: "column", alignItems: "center", justify: "center" }}>
          {!item.image && <Box style={{ flex: '1 1 auto', fontSize: "150px", color: "#5D6B77" }} component={RestaurantMenuIcon} />}
        </Box>

        <Box width="100%" height="60%" style={{ backgroundColor: "white" }} padding="25px">
          <Typography variant="h4">{item.name}</Typography><br />
          <Typography> {item.description}</Typography><br />
          <Typography> {item.price}</Typography>
          {
            item.prices &&
            <RadioGroup
              ref={priceRadioGroupRef}
              aria-label="prices-selection"
              name="prices"
              value={selectedPrice.toString()}
              onChange={handlePriceSelection}
            >
              {
                item.prices.map((priceInfo) => {
                  return (
                    <FormControlLabel
                      value={item.prices.indexOf(priceInfo).toString()}
                      key={"priceInfo:" + item.prices.indexOf(priceInfo)}
                      control={<Radio />}
                      label={priceInfo.price + " " + priceInfo.note}
                    />
                  );
                })
              }
            </RadioGroup>
          }

          <Box className={styles.flexColumn} marginTop="25px" marginBottom="25px">
            <TextField id="filled-basic" label="Note for kitchen" variant="filled" multiline={true} rows={4} />
          </Box>

          <Box className={styles.flexRow}>
            <Button
              className={styles.flexButton}
              variant="contained"
              component={Link}
              to={`/demo/restaurant/${restaurantName || "demo"}/menus/${menuName}`}
            > Return to menu </Button>

            <div className={styles.flex} />
            <Button className={styles.flexButton} color="primary" variant="contained" onClick={handleClickOpen}>Order</Button>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Order Confirmation</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you would like to
            send this order to the kitchen?
        </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" autoFocus>
            Order
        </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  flex: {
    flex: '1 1 auto',
    marginTop: "auto"
  },
  flexButton: {
    flex: '1 2 auto',
    marginTop: "auto",
    marginBottom: "10px"
  },

}));