import React from "react";
import logo from '../assets/get-the-menu.png'
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Box, Tabs, Tab } from "@material-ui/core";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}


export function RestaurantNavBar({ value, restaurant, myOrder, params }) {

  return (
    <HideOnScroll>

    <AppBar elevation={3} position="sticky" color="white">

      <Box display="flex" justifyContent="center">
        <img src={logo} alt="Get The Menu" style={{maxHeight: "50px", paddingTop: "5px" }} />

        <Toolbar>
          <Typography variant="h5" style={{ color: "#1E2B37" }}> {restaurant.name}</Typography>
        </Toolbar>
      </Box>

      <Tabs value={value} aria-label="tabs" variant="fullWidth">
        <Tab label="Covid-19 Contact Form" component={Link} to={`/menu/covid/`} />
        {
          restaurant.menus.map(menu=>{
            return <Tab key={`tab:${menu.name}`} label={menu.name} component={Link} to={`/menu/menus/${menu.name}`} />
          })
        }
        <Tab label="My Order" component={Link} to={`/menu/my-order`} />
      </Tabs>
    </AppBar>
    </HideOnScroll>

  );
}

// Style