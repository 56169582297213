export default [
  {
    name: "Merlot",
    prices: [{ price: "$9", note: "Glass" }, { price: "$34", note: "Bottle" }],
    description: "House"
  },
  {
    name: "Chianti",
    prices: [{ price: "$10", note: "Glass" }, { price: "$40", note: "Bottle" }],
    description: "Toscana"
  },
  {
    name: "Cingalino",
    prices: [{ price: "$10", note: "Glass" }, { price: "$40", note: "Bottle" }],
    description: "Toscana"
  },
  {
    name: "Nero D'avola",
    prices: [{ price: "$10", note: "Glass" }, { price: "$40", note: "Bottle" }],
    description: "Sicilia"
  },
  {
    name: "Valpolicella",
    prices: [{ price: "$11", note: "Glass" }, { price: "$45", note: "Bottle" }],
    description: "Veneto"
  },
  {
    name: "Barbera D'asti",
    prices: [{ price: "$12", note: "Glass" }, { price: "$48", note: "Bottle" }],
    description: "Piemonte"
  },
  {
    name: "Sangiovese",
    prices: [{ price: "$12", note: "Glass" }, { price: "$48", note: "Bottle" }],
    description: "Toscana"
  },
  {
    name: "Rubrato",
    prices: [{ price: "$12", note: "Glass" }, { price: "$48", note: "Bottle" }],
    description: "Campania"
  },
  {
    name: "Primitivo",
    prices: [{ price: "$12", note: "Glass" }, { price: "$48", note: "Bottle" }],
    description: "Puglia"
  },
  {
    name: "Cabernet Souvignon",
    prices: [{ price: "$12", note: "Glass" }, { price: "$48", note: "Bottle" }],
    description: "California"
  },
  {
    name: "Piccini Oro",
    prices: [{ price: "$12", note: "Glass" }, { price: "$48", note: "Bottle" }],
    description: "Toscanna"
  },
  {
    name: "Zinfaldel",
    prices: [{ price: "$12", note: "Glass" }, { price: "$48", note: "Bottle" }],
    description: "California"
  },
  {
    name: "Shiraz",
    prices: [{ price: "$12", note: "Glass" }, { price: "$48", note: "Bottle" }],
    description: "Australia"
  },
  {
    name: "Pinot Nero",
    prices: [{ price: "$13", note: "Glass" }, { price: "$55", note: "Bottle" }],
    description: "Trentino"
  },
  {
    name: "Chianti Riserva",
    prices: [{ price: "$16", note: "Glass" }, { price: "$70", note: "Bottle" }],
    description: "Toscana"
  },
  // First half

  {
    name: "Roma",
    prices: [{ price: "$60", note: "Bottle" }],
    description: "Lazio"
  },
  {
    name: "Ciro' Rosso",
    prices: [{ price: "$65", note: "Bottle" }],
    description: "Calabria"
  },
  {
    name: "La Fogliola",
    prices: [{ price: "$65", note: "Bottle" }],
    description: "Marche"
  },
  {
    name: "Cannonau",
    prices: [{ price: "$60", note: "Bottle" }],
    description: "Sardegna"
  },
  {
    name: "Grignolinno D'asti",
    prices: [{ price: "$65", note: "Bottle" }],
    description: "Piemonte"
  },
  {
    name: "Dolcetto D'alba",
    prices: [{ price: "$70", note: "Bottle" }],
    description: "Piemonte"
  },
  {
    name: "Morellino Di Scansano",
    prices: [{ price: "$80", note: "Bottle" }],
    description: "Toscana"
  },
  {
    name: "Rosso Di Montalcino",
    prices: [{ price: "$80", note: "Bottle" }],
    description: "Toscana"
  },
  {
    name: "Valpolicella Ripasso",
    prices: [{ price: "$80", note: "Bottle" }],
    description: "Vento"
  },
  {
    name: "Langhe Nebbiolo",
    prices: [{ price: "$80", note: "Bottle" }],
    description: "Piemonte"
  },
  {
    name: "Taurasi",
    prices: [{ price: "$110", note: "Bottle" }],
    description: "Campania"
  },
  {
    name: "Poppone",
    prices: [{ price: "$130", note: "Bottle" }],
    description: "House"
  },
  {
    name: "Amarone",
    prices: [{ price: "$23", note: "Glass" }, { price: "$110", note: "Bottle" }],
    description: "Vento"
  },
  {
    name: "Barolo",
    prices: [{ price: "$24", note: "Glass" }, { price: "$120", note: "Bottle" }],
    description: "Piemonte"
  },
  {
    name: "Brunello Di Montalcino",
    prices: [{ price: "$24", note: "Glass" }, { price: "$130", note: "Bottle" }],
    description: "Toscana"
  },
]

