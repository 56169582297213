import React, { useEffect, useState } from 'react';
import Box from "@material-ui/core/Box";
import { AppBar, Toolbar, IconButton, Typography, Button, TextField, Container } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import CompleteFormWarning from '../components/CompleteFormWarning';
import LoadingPage from './LoadingPage';

export default function MenuItemPage({ item, menuName, restaurantName, restaurantId }) {
  const [open, setOpen] = React.useState(false); // TODO: understand react component state.
  const [selectedPrice, setSelectedPrice] = React.useState(0);
  const [note, setNote] = React.useState();
  let [isLoading, setIsLoading] = useState(true);
  let [myContactInfo, setMyContactInfo] = useState(null);

  useEffect(() => {
    api.covid.getMyContactInfo()
      .then(response => {
        console.log("getMyContactInfo");
        console.log(response);
        setMyContactInfo(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setMyContactInfo(null);
        setIsLoading(false);
      });

  }, []);

  const priceRadioGroupRef = React.useRef(null);
  const history = useHistory();

  const handlePriceSelection = (event) => {
    console.log("value: " + event.target.value);
    setSelectedPrice(event.target.value);
    console.log("selectedPrice: " + selectedPrice);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    console.log("submitting");
    console.log("handle submit: ");
    console.log(item.prices ? item.prices[selectedPrice] : item.price);

    let p = item.prices ? item.prices[selectedPrice].price + " " + item.prices[selectedPrice].note : item.price;
    api.order.submitOrder({ price: p, order: item.name, notes: note })
      .then(response => {
        console.log("acceptOrders");
        console.log(response);
        history.push("/menu/my-order");
      })
      .catch(error => {
        console.log(error);
        console.log("failed to submit, our session probably expired");
      });

    setOpen(false);
  };

  const onNoteChanged = (e) => {
    setNote(e.target.value);
  };

  const covidContactInfo = false;

  const styles = useStyles();
  return (
    // <Container style={{ padding: "0px" }}>
    isLoading ? <LoadingPage/> :
    <Container style={{
      // flexGrow:1,
      // backgroundColor: "white",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0px",
    }} >

      <AppBar color="white" position="relative">
        <Toolbar>
          <IconButton
            aria-label="back"
            component={Link}
            to={`/menu/menus/${menuName}`}
          > <ArrowBackIosIcon /> </IconButton>

          <Typography
            variant="h6"
            color="inherit"
            component={Link}
            to={`/menu/menus/${menuName}`}
            style={{ textDecoration: 'none' }}
          > {restaurantName} </Typography>

        </Toolbar>
      </AppBar>

      <Box width="100%" style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }} >
        <Box width="100%" style={{ minHeight: "150px", flexGrow: 3, backgroundImage: `url(${item.image})`, backgroundSize: "cover", display: "flex", backgroundColor: "#F1F2F4", direction: "column", alignItems: "center", justify: "center" }}>
          {!item.image && <Box style={{ flex: '1 1 auto', fontSize: "150px", color: "#5D6B77" }} component={RestaurantMenuIcon} />}
        </Box>

        <Box width="100%" style={{
          flexGrow: 1,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "25px",
        }} >
          <Typography variant="h4">{item.name}</Typography><br />
          <Typography> {item.description}</Typography><br />
          <Typography> {item.price}</Typography>
          {
            item.prices &&
            <RadioGroup
              ref={priceRadioGroupRef}
              aria-label="prices-selection"
              name="prices"
              value={selectedPrice.toString()}
              onChange={handlePriceSelection}
            >
              {
                item.prices.map((priceInfo) => {
                  return (
                    <FormControlLabel
                      value={item.prices.indexOf(priceInfo).toString()}
                      key={"priceInfo:" + item.prices.indexOf(priceInfo)}
                      control={<Radio />}
                      label={priceInfo.price + " " + priceInfo.note}
                    />
                  );
                })
              }
            </RadioGroup>
          }


          {
            myContactInfo ?
              (
                <Box>
                  <Box className={styles.flexColumn} marginTop="25px" marginBottom="25px">
                    <TextField id="filled-basic" label="Note for kitchen" variant="filled" multiline={true} rows={4} onChange={onNoteChanged} />
                  </Box>

                  <Box className={styles.flexRow}>
                    <Button
                      className={styles.flexButton}
                      variant="contained"
                      component={Link}
                      to={`/menu/menus/${menuName}`}
                    > Return to menu </Button>
                    <div className={styles.flex} />
                    <Button className={styles.flexButton} color="primary" variant="contained" onClick={handleClickOpen}>Order</Button>
                  </Box>

                </Box>
              ) :
              <CompleteFormWarning />
          }
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Order Confirmation</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you would like to
            send this order to the kitchen?
        </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" autoFocus>
            Order
        </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  flex: {
    flex: '1 1 auto',
    marginTop: "auto"
  },
  flexButton: {
    flex: '1 2 auto',
    marginTop: "auto",
    marginBottom: "10px"
  },

}));