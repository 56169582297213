import React from "react";
import logo from './logo_dark.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import NavMenu from "./NavMenu";

export function NavBar() {
  return [
    <AppBar elevation={3} position="sticky" style={{backgroundColor:"transparent"}}>
      <Toolbar>
        <a href="/" >
          <img src={logo} alt="Get The Menu" style={{maxHeight:"50px", paddingTop:"5px"}}/>
        </a>
        <div style={{flexGrow: 1,}}></div>
        <NavMenu/>
      </Toolbar>

    </AppBar>
  ];
}