import React from "react";
import { CssBaseline, Box } from '@material-ui/core';
import RestaurantPage from "./pages/RestaurantPage/"
import {
  Switch,
  Route,
} from "react-router-dom";

export default function Demo() {
  return (
    <Box>
      <CssBaseline />
      <Switch>
        <Route path="/demo/restaurant/:restaurantName">
          <RestaurantPage />
        </Route>
      </Switch>
    </Box>
  );
}
