import React from "react";
import logo from './get-the-menu.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import NavMenu from "./NavMenu";
import { Typography } from "@material-ui/core";

export function NavBar() {
  return [
    <AppBar elevation={3} position="fixed" style={{backgroundColor:"white"}}>
      <Toolbar>
        <a href="/" >
          <img src={logo} alt="Get The Menu" style={{maxHeight:"50px", paddingTop:"5px"}}/>
        </a>
        <div style={{flexGrow: 1,}}></div>
        <Typography>
          DEV TOOL
        </Typography>
        <div style={{flexGrow: 1,}}></div>
        <NavMenu/>
      </Toolbar>

    </AppBar>
  ];
}