import React from "react";
import { CssBaseline, Box } from '@material-ui/core';
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import HomePage from "./pages/HomePage";
import NavBar from "./components/NavBar";
import RenameTable from "./pages/RenameTable";
import ChangeRestaurant from "./pages/ChangeRestaurant";

import {
  Switch,
  Route,
} from "react-router-dom";

export default function Admin() {
  return (
    <Box>
      <CssBaseline />
      <NavBar />

      <Switch>
        <Route path="/admin/login" component={LoginPage} />
        <ProtectedRoute path="/admin/rename-table/:alias" component={RenameTable} />
        <ProtectedRoute path="/admin/change-restaurant/:alias" component={ChangeRestaurant} />
        <ProtectedRoute path="/admin/" component={HomePage} />
      </Switch>
    </Box>
  );
}
