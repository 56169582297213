import React from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ErrorIcon from '@material-ui/icons/Error';

export default function CompleteFormWarning() {

  return (
    <Box padding="15px" width="100%" style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexGrow:1
    }}>

   
      <Typography style={{ color: "black", fontWeight: 500, fontSize: "18px", lineHeight: "20px" }}> 

        <ErrorIcon  style={{ color: "black", fontSize: 30, width:"100%"}} />

        Please complete and submit covid contact form before sending orders to kitchen.
      </Typography>

      <Button size="large" variant="contained" color="primary" component={Link} to={`/menu/covid/` } style={{padding:"5px", marginTop:"10px"}} >Complete Covid Form</Button>
    </Box>
  );
}
