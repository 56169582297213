import React from 'react'
import { Redirect } from 'react-router-dom'
import api from "../../api";
import {
  Route,
} from "react-router-dom";


class ProtectedRoute extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      isLoggedIn: false
    };

    // Your axios call here
    api.auth.restaurant()
      .then(response=>{
        if (response.data)
          this.setState(() => ({ isLoading: false, isLoggedIn: true }));
        else
          this.setState(() => ({ isLoading: false, isLoggedIn: false }));
      })
      .catch(error=>{
        this.setState(() => ({ isLoading: false, isLoggedIn: false }));
      });
  }

  render() {

    return this.state.isLoading ? "Loading..." :
      this.state.isLoggedIn ?
        <Route path={this.props.path} component={this.props.component} exact={this.props.exact} /> :
        <Redirect to={{ pathname: '/restaurant/login', state: { from: this.props.location } }} />
  }

}

export default ProtectedRoute;