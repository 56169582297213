import React from 'react';
import Box from "@material-ui/core/Box";
import OrderItem from '../../components/OrderItem';

export default function MyOrdersPage({orderedItems}) {
  let orderedItem = {
    name:"Burrata", 
    priceInfo:"$11 Half", 
    confirmed:true
  };

  let orderedItem2 = {
    name:"Cesare", 
    priceInfo:"$12", 
    confirmed:false
  };

  orderedItems = orderedItems!= null ? orderedItems : [orderedItem, orderedItem2];
  
  return (
    <Box>
      {
        orderedItems.map(item=>{
          return (
            <OrderItem orderedItem={item}></OrderItem>
          )
        })
      }
    </Box>
  );
}