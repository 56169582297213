import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogContentText from '@material-ui/core/DialogContentText';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import HistoryIcon from '@material-ui/icons/History';
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

const drawerWidth = 150;

const useStyles = makeStyles((theme) => ({

  drawer: {
    width: 150,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderColor: "black",
    backgroundColor: '#0A0A0A',

  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerItem: {
    padding: theme.spacing(3),
    paddingTop: window.screen.height / 10
  },
  largeIcon: {
    width: 60,
    height: 60,
    color: '#707070'
  },
  largeIconSelected: {
    width: 60,
    height: 60,
    color: 'white'
  },
  tabDescription: {
    color: '#707070',
    textDecoration: 'none',
  },
  tabDescriptionSelected: {
    color: 'white',
    textDecoration: 'none',
  }
}));

export default function KitchenSideBar({ pageIndex }) {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <List>
        {['PENDING ORDERS', 'ORDER HISTORY'].map((text, index) => (
          <ListItem button key={text}>
            <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={index === 0 ? "/kitchen/orders" : "/kitchen/history"}>
              <Box className={classes.drawerItem}>
                {index % 2 === 0 ? <FastfoodIcon className={pageIndex === "pending" ? classes.largeIconSelected : classes.largeIcon} />
                  : <HistoryIcon className={pageIndex === "history" ? classes.largeIconSelected : classes.largeIcon} />}
                <DialogContentText className={(pageIndex === "pending" && index === 0) || (pageIndex === "history" && index === 1) ?
                  classes.tabDescriptionSelected : classes.tabDescription}>{text}</DialogContentText>
              </Box></Link>

          </ListItem>
        ))}
      </List>

    </Drawer>

  );
}
