import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle"
const styles= {
    color: 'white',
};

export default class Clock extends React.Component {
    
    state ={time:null}
     
     componentDidMount() {
         setInterval(() => {
             this.setState({time:new Date().toLocaleTimeString()})}, 1000)
        }
 
        
     render() {
             return (
             <DialogTitle style={styles}> {this.state.time}</DialogTitle>
   
              );
          }
   }