import Box from "@material-ui/core/Box";
import OrderItem from '../components/OrderItem';
import api from '../../api';
import { Typography } from '@material-ui/core';
import ReactPullToRefresh from 'react-pull-to-refresh';
import React, { useEffect, useState } from 'react';
import LoadingPage from './LoadingPage';

export default function MyOrdersPage() {

  let [orderedItems, setOrderedItems] = useState(null);
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.order.getMyOrders()
      .then(response => {
        console.log("getMyOrders");
        console.log(response);
        setIsLoading(false);
        setOrderedItems(response.data);
      })
      .catch(error => {
        console.log(error);
        setOrderedItems(null);
        setIsLoading(false);
      });

  }, []);

  function handleRefresh(resolve, reject) {
    console.log("refreshing");
    // do some async code here
    api.order.getMyOrders()
      .then(response => {
        console.log(response.data);
        setOrderedItems(response.data);
        resolve();
      })
      .catch(
        error => reject()
      );
  }

  return (
    <ReactPullToRefresh onRefresh={handleRefresh} >
      <Box minHeight="90vh">
        <Typography>Pull to Refresh Status</Typography>
        { isLoading ? <LoadingPage/> : 
          orderedItems && orderedItems.map ?
            orderedItems.map(item => {
              return (<OrderItem key={item._id} orderedItem={item}></OrderItem>)
            })
            : "No order items!"
        }
      </Box>
    </ReactPullToRefresh>
  );
}