import React from "react";
import logo from '../assets/get-the-menu.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Box } from "@material-ui/core";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}


export default function RestaurantHeader({ value, restaurant, myOrder, params }) {

  return (
    // <HideOnScroll>

    //<AppBar elevation={value == 1 ? 0 : 3} position="sticky" color="white" variant="fullWidth">

      <Box display="flex" justifyContent="center">
        <img src={logo} alt="Get The Menu" style={{maxHeight: "40px", paddingTop: "8px",  paddingBottom: "12px", paddingLeft:"12px"}} />
        <Box style={{flexGrow:1}}/>
        <Toolbar>
          <Typography variant="h5" style={{ color: "#1E2B37" }}> {restaurant.name}</Typography>
        </Toolbar>
      </Box>
      
      
    //</AppBar>

    // </HideOnScroll>

  );
}

// Style