import React from 'react';
import Button from "@material-ui/core/Button";
import { useHistory } from 'react-router-dom';
import Cookies from "js-cookie";

export default function LogoutButton() {
  let [loggedIn, setLoggedIn] = React.useState(Cookies.get("restaurant"))
  const history = useHistory();
  let onLogout = (event) => {
    Cookies.remove('restaurant');
    history.push("/restaurant/login");
    setLoggedIn(false);
  }
  
  // return Cookies.get("restaurant") ?
  return loggedIn ?
    <Button variant="contained" onClick={onLogout}>Log out</Button>
    : "";
  
}