import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import NavBar from '../components/NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useRouteMatch} from "react-router-dom";
import { Tabs, Tab } from '@material-ui/core';
import CovidContactInfo from './Dash/CovidContactInfo';
import Help from './Dash/Help';
import { faUser, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
// import { faUser, faPaperPlane, faCoffee, faQrcode, faShieldVirus, faWineGlass, faChair, faUtensils,  faEdit } from '@fortawesome/free-solid-svg-icons'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {
        value === index && children
        // (<Box p={3}>
        //   <Typography>{children}</Typography>
        // </Box>)
      }
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: "row"
  },

  drawer: {
    width: 200,
    flexShrink: 0,
    // width: drawerWidth,
    // flexGrow: 1,
    backgroundColor: "#F4F6F8"
  },

  content: {
    minHeight: "90vh",
    flexGrow: 1,
    flexDirection: "row",
    // display: "flex",
    // padding: theme.spacing(3),
  },

  page: {
    // minHeight: "90vh",
    // width:"100%",
    // minHeight: "90vh",
    flexGrow: 1,
    display: "flex",
    // padding: theme.spacing(3),
  },

  smallTitle: { fontSize: "14px", color: "#5F7382" },
  mediumTitle: { fontSize: "22px", color: "#5F7382", marginTop: "-7px", fontWeight: 500 },
  cardTitle: { fontWeight: 500 },
  tabs: { width: 200, borderRight: `1px solid ${theme.palette.divider}` },

}));

const tabSections = [
  {
    text: 'Covid Contact Info',
    icon: <FontAwesomeIcon icon={faUser} />,
    page: <CovidContactInfo />
  },
  {
    text: 'Help',
    icon: <FontAwesomeIcon icon={faPaperPlane} />,
    page: <Help />
  },
];


export default function DashboardPage({ user, match }) {
  user = user ? user : { accountType: "Owner", name: "Chris" };
  const classes = useStyles();
  const [currentTab, setCurrentTab] = React.useState(0);
  console.log(useRouteMatch());

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function RestaurantDrawer() {
    return (
      <Drawer className={classes.drawer} variant="permanent">
        <Toolbar width="100%" />
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          value={currentTab}
          onChange={handleTabChange}
          aria-label="dashboard tabs"
          className={classes.tabs}
        >
          {
            tabSections.map((item, index) => <Tab icon={item.icon} label={item.text} />)
          }
        </Tabs>
      </Drawer>
    );
  }

  function RestaurantContact() {
    return (
      <Box className={classes.content}>
        {
          tabSections.map((item, index) =>
            <TabPanel value={currentTab} index={index} className={classes.page}>
              {item.page}
            </TabPanel>
          )
        }
      </Box>
    );
  }

  return [
    <NavBar />,
    <Box className={classes.root}>
      <RestaurantDrawer match={match} />
      <RestaurantContact></RestaurantContact>
    </Box>
  ];
}