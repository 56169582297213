import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Divider, Paper } from "@material-ui/core";

export default function CovidContactInfo({ restaurant = { name: "Jack's - London" } }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Paper className={classes.header}>
        <Typography variant="h4"> {restaurant.name} </Typography>
      </Paper>

      <Container maxWidth="sm" className={classes.container}>
        <Box className={classes.spacer} />
        <Typography>Need support with anything? We’re here to help!</Typography>
        <Typography>Email us at:</Typography><br />
        <Typography>GetTheMenu.ca@gmail.com</Typography>
        <Divider style={{ width: "75%" }} />
        <Box className={classes.spacer} />
        <Typography>Login on your phone and scan a QR code to set that table’s name.</Typography><br />
      </Container>

    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "90vh",
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    width: "100%",
    padding: theme.spacing(3, 2),
    display: "flex",
    flexDirection: "column",
    borderRadius: 0
  },
  spacer: { flexGrow: 1 },
  spacer2: { flexGrow: 2 },

}));