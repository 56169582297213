import React from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { colors, CssBaseline } from '@material-ui/core';
import { Link } from "react-router-dom";
import { NavBar } from './NavBar';
import background_restaurant from './background_restaurant.jpg'

export default function LandingPage() {

  return (
    // <Container style={{padding:"0px"}}>
    <Box height="100vh"  width="100%" >
      <Box height="100vh"  width="100%" style={{ position: 'fixed', background: "url(" + background_restaurant + ")", backgroundSize: "cover" }}></Box>
        <CssBaseline />
        <NavBar />
        <Container maxWidth="sm" style={{position: 'relative'}}>
        <Box height="10vh" />
          <Container style={{top: '25%', right: '25%',}}>
            <Typography variant="h2" align="right" style={{ color: "white", backgroundColor: colors.black }}> 
            WHAT IS
            </Typography>
            <Typography variant="h2" align="right" style={{ color: "white", backgroundColor: colors.black, fontWeight: 'bold'}}> 
            GET THE MENU
            </Typography>
            <Box height="2vh" />
            <Typography variant="h5" align="right" style={{ right: '20%', color: "white", backgroundColor: colors.black }}> 

            Imagine if online menus and COVID-19 contact 
            collection for restaurants were <b>as easy as <br/>
             scanning a QR code on a table.</b> 
             <br></br> <br></br>
            Give customers the freedom to <b>send orders
            directly to the kitchen on your phone</b>, even 
            when the server isn't there.
            </Typography>
            
            <Box height="2vh" />
            <Box textAlign='right'>
            <Button variant="contained" color="primary" style={{width: 400}} component={Link} to="/demo/restaurant/demo" >View Demo</Button>
            </Box>

       

        
      
          <br />
          <Box height="50vh" />
          <Typography variant="h2" align="right" style={{ color: "white", backgroundColor: colors.black, fontWeight: 'bold'}}> 
            CONTACT US
            </Typography>
            <Typography variant="h5" align="right" style={{ right: '20%', color: "white", backgroundColor: colors.black }}> 
            Are you interested in implementing our service in your restaurant?<br></br>
            <br></br>
            Email us at <b>GetTheMenu.ca@gmail.com</b></Typography>
          
            <Box height="50vh" />
          </Container>

          </Container>
        
       

          
      </Box>
    // </Container>
  );
}