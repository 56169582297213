import React, { useEffect, useState } from 'react';
import KitchenSideBar from '../components/kitchen_end/KitchenSideBar';
import Box from "@material-ui/core/Box";
import { makeStyles } from '@material-ui/core/styles';
import OrderCard from '../components/kitchen_end/OrderCard';
import GridList from '@material-ui/core/GridList';
import AcceptOrderButton from '../components/kitchen_end/AcceptOrderButton';
import api from '../../api';
import LoadingPageKitchen from './LoadingPageKitchen';

export default function PendingOrdersPage() {
  const classes = useStyles();
  let [pendingOrders, setPendingOrders] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let [selectedOrders, setSelectedOrders] = useState([]);

  let selectOrder = (order) => {
    if (selectedOrders.includes(order)) return;
    selectedOrders.push(order);

    setSelectedOrders([...selectedOrders]);
    console.log("selected: ", order);
  }

  let unselectOrder = (order) => {
    // if (!selectedOrders.includes(order)) return;
    let filteredItems = selectedOrders.filter(item => item !== order);
    setSelectedOrders([...filteredItems]);
    console.log("unselected: ", order);
  }

  let onAccepted = (newPendingOrders) => {
    setPendingOrders(newPendingOrders);
    setSelectedOrders([]);
    console.log("We accepted the order and this is the new pending list:", pendingOrders)
  }

  useEffect(() => {
    api.kitchen.getUpcomingOrders()
      .then(response => {
        console.log("getUpcomingOrders");
        console.log(response);
        setPendingOrders(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setPendingOrders(null);
        setIsLoading(false);
      });

  }, []);

  return (
    <Box className={classes.root}>
      <KitchenSideBar pageIndex='pending' />
      <Box className={classes.mainArea}>

        {
          isLoading ? <LoadingPageKitchen></LoadingPageKitchen>:
            !pendingOrders?.map ? "no orders" :

              pendingOrders.map((order) => {
                return (
                  <GridList key={order._id} sm={12} >
                    <OrderCard
                      key={order._id}
                      order={order}
                      isSelected={selectedOrders.includes(order)}
                      onSelected={selectOrder}
                      onUnselected={unselectOrder}
                    />
                  </GridList>
                )
              })
        }
      </Box>

      <AcceptOrderButton selectedOrders={selectedOrders} onAccepted={onAccepted}/>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: "100vh",
    backgroundColor: "#262626",
    position: "absolute"
  },

  mainArea: {
    flexGrow: 1,
    paddingTop: "100px",
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

}));