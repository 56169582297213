import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import {SkipPreviousIcon, PlayArrowIcon, SkipNextIcon} from '@material-ui/icons/';

function cleanUrl(url) {
  return url.toLowerCase().split(" ").join("-");
}

export default function MenuItemCard({menuItem, category, menu}) {
  let { name, price, prices, description, image } = menuItem;

  const classes = useStyles();
  name = name || "N/A";
  description = description || "N/A";
  // price = price || "$5.95";

  return (

    // TODO: pass restaurant name as props, swap out /demo/ with ${restaurantName}
    <Card className={classes.card} elevation={0} component={Link} to={cleanUrl(`/demo/restaurant/demo/menus/${menu.name}/${category.name}/${menuItem.name}`)}>

      <div className={classes.details} >
        <CardContent className={classes.content}>
          <Typography style={{ fontSize: 16, color: "#1E2B37", fontWeight: 500, lineHeight: "16px" }}>
            {name}
          </Typography>

          <Typography style={{ fontSize: 14, color: "#1E2B37", fontWeight: 400, lineHeight: "14px", marginTop: "5px", marginBottom: "5px" }}>
            {price}
            {
              prices && (
                prices.map(priceObject => [
                  // <Box height="1px"/>,
                  // <Typography style={{ fontSize: 12, color: "#1E2B37", fontWeight: 400, lineHeight: "12px", marginBottom: "2px" }}>
                  priceObject.price + " " + priceObject.note + "  "
                  // </Typography>
                ])
              )
            }
          </Typography>

          <Typography style={{ fontSize: 14, lineHeight: "18px", color: "#1E2B37", fontWeight: 350, wordWrap: "break-word", overflow: "hidden" }}>
            {description}
          </Typography>
        </CardContent>
      </div>
      <div className={classes.flex} />
      {image && <CardMedia className={classes.image} image={image}/>}
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  card: { 
    border: "1px solid #DEE3E9", 
    borderRadius: "0px", 
    overflow: "hidden", 
    textOverflow: "ellipsis",
    display: 'flex',
    maxHeight: 180,
    textDecoration: 'none'
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  flex: {
    flex: '1 0 auto',
    marginTop: "auto"
  },
  image: {
    background: "lightGray",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minWidth: 125,
    maxWidth: "30%",
    minHeight: "100px",
    maxHeight: "100%",

    verticalAlign: "middle"
  },
}));