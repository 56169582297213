import React from "react";
import logo from '../assets/get-the-menu.png'
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Box, Tabs, Tab } from "@material-ui/core";

// In our demo we will allow the logo to return users to the landing page,
// but in our real web-app, customers won't be able to navigate away from the menu.
// Component
export function RestaurantNavBar({ value, restaurant, myOrder, params }) {

  return (
    <AppBar elevation={3} position="sticky" color="white">

      <Box display="flex" justifyContent="center">
        <Link to="/" >
          <img src={logo} alt="Get The Menu" style={{maxHeight: "50px", paddingTop: "5px" }} />
        </Link>

        <Toolbar>
          <Typography variant="h5" style={{ color: "#1E2B37" }}> {restaurant.name}</Typography>
        </Toolbar>
      </Box>

      <Tabs value={value} aria-label="tabs" variant="fullWidth">
        <Tab label="Covid-19 Contact Form" component={Link} to={`/demo/restaurant/${restaurant.urlName || "demo"}/`} />
        {
          restaurant.menus.map(menu=>{
            return <Tab key={`tab:${menu.name}`} label={menu.name} component={Link} to={`/demo/restaurant/${restaurant.urlName || "demo"}/menus/${menu.name}`} />
          })
        }
        <Tab label="My Order" component={Link} to={`/demo/restaurant/${restaurant.urlName || "demo"}/my-order`} />
      </Tabs>
    </AppBar>
  );
}

// Style