import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import api from "../../api";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    // height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
}));


export function CovidForm({contactInfoCallback}) {
  const classes = useStyles();
  
  let [isSubmitting, setIsSubmitting] = useState(false);

  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState(""); 

  // Try submitting the covid contact info, if successful we receive the contactInfo in the response,
  // and pass it back to the parent component through the contactInfoCallback.
  // Todo: handle errors  better?
  let onSubmit = () => {
    setIsSubmitting(true);
    api.covid.submitContactInfo(email, phoneNumber, firstName, lastName)
      .then(response=>{
        console.log("submit contact info response: ");
        console.log(response);
        
        let contactInfo = response.data;
        contactInfoCallback(contactInfo);
      })
      .catch(error=>{
        console.log(error);
        setIsSubmitting(false);
      })
  }


  return (
    <Card>
      <CardContent >
        <Box padding="15px">
          <Typography variant="h5" component="h3" >Covid Contact Info</Typography>
          <form className={classes.root} noValidate autoComplete="off">

            <Typography style={{ color: "#5D6B77" }}>
              Help improve covid-19 contract tracing
              to prevent outbreaks by submitting your
              Contact information.
            </Typography>

            <TextField
              id="name-input"
              label="First Name"
              name="fname"
              margin="normal"
              fullWidth
              placeholder="John"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              variant="outlined"
              required={true}
              type="text"
              onChange={e => setFirstName(e.target.value )}
            />
            <TextField
              id="name-input"
              label="Last Name"
              name="lname"
              margin="normal"
              fullWidth
              placeholder="Smith"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              variant="outlined"
              required={true}
              type="text"
              onChange={e => setLastName(e.target.value )}
            />

            <TextField
              id="email-input"
              label="Email"
              name="email"
              autocomplete="email"
              margin="normal"
              fullWidth
              placeholder="JohnSmith@gmail.com"

              InputLabelProps={{
                shrink: true,
              }}

              size="small"
              variant="outlined"
              type="email"
              required={true}
              onChange={e => setEmail(e.target.value )}
            />

            <TextField
              id="phone-input"
              label="PhoneNumber"
              fullWidth
              placeholder="519-555-5555"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              variant="outlined"
              
              autocomplete="tel"
              type="tel" 
              name="phone"
              required={true}
              onChange={e => setPhoneNumber(e.target.value )}
            />

            <Button onClick={onSubmit} disabled={isSubmitting} variant="contained" color="primary" style={{ marginTop: 15 }}>Submit</Button>

          </form>

        </Box>
      </CardContent>
    </Card>
  );
}
