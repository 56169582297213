import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


export default function CovidInfo({info}) {
  info = info ? info : {firstName: "Isaiah", lastName: "Ballah", email: "isaiahballah@gmail.com", phoneNumber: "519-555-5555"};

  return (
    <Card>
      <CardContent>

        <Box padding="15px">
          <Typography variant="h5" component="h3" >Submitted Covid-19 Contact Info</Typography>
            
            <Typography style={{ color: "#5D6B77" }}> First Name: {info.firstName} </Typography>
            <Typography style={{ color: "#5D6B77" }}> Last Name: {info.lastName} </Typography>
            <Typography style={{ color: "#5D6B77" }}> Phone Number: {info.phoneNumber} </Typography>
            <Typography style={{ color: "#5D6B77" }}> Email: {info.email} </Typography>
        </Box>

      </CardContent>
    </Card>
  );
}
