import React from "react";
import { CssBaseline, Box, AppBar, Toolbar } from '@material-ui/core';
import {
  Switch,
  Route, Link
} from "react-router-dom";
import PendingOrdersPage from "./pages/PendingOrdersPage";
import OrderHistoryPage from "./pages/OrderHistoryPage";
import LoginKitchenPage from "./pages/LoginKitchenPage";
import ProtectedRoute from "./components/kitchen_end/ProtectedRoute";
import Clock from "./components/kitchen_end/Clock";
import logo from "./assets/logo_dark.png"
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'black',
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function Kitchen() {
  const classes = useStyles();

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
         <Toolbar flex="1">
          <Link to="/" >
            <img src={logo} alt="Get The Menu" style={{ maxHeight: "60px", paddingTop: "10px", paddingLeft: "15px", paddingRight: "15px" }} />
          </Link>
          <Box style={{flexGrow:1}}/>
          <Clock />
        </Toolbar>
      </AppBar>

      <Switch>

        <Route path="/kitchen/login">
          <LoginKitchenPage />
        </Route>

        <ProtectedRoute
          path="/kitchen/orders"
          component={PendingOrdersPage}
        />
        <ProtectedRoute
          path="/kitchen/history"
          component={OrderHistoryPage}
        />

        <ProtectedRoute
          path="/kitchen/"
          component={PendingOrdersPage}
        />



      </Switch>
    </Box>
  );
}
