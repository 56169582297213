import React from 'react';
import { Card, CardContent, Grid, Box, Typography } from '@material-ui/core';
import MenuItemCard from './MenuItemCard';
// import { makeStyles, useTheme } from '@material-ui/core/styles';

export default function MenuCard({ menu }) {

  return (
    <Card color="#fff" elevation={0} style={{ border: "1px solid #DEE3E9",  }}>
      <CardContent>
        <Box padding="0px">
          <Typography variant="h5" component="h3" >{menu.name || "Menu"}</Typography>

          {menu.categories.map((category) => (
            <Box key={`Box:menu-category:${menu.name}-${category.name}`}>
              <Box height="25px" />
              <Typography variant="h5" component="h3" >{category.name}</Typography>
              <Box height="15px" />

              <Grid container spacing={1}>
                {category.menuItems.map((menuItem) => (
                  <Grid 
                    key={`Grid:menuItems:${menu.name}-${category.name}-${menuItem.name}`}
                    item sm={12} md={6} xs={12}
                  >
                    <MenuItemCard
                      key={"MenuItemCard:" + menu.name + "-" + category.name + "-" + menuItem.name}
                      menuItem={menuItem} menu={menu} category={category} />
                  </Grid>
                ))}
              </Grid>

              <Box height="30px" />
            </Box>
          ))}

        </Box>
      </CardContent>
    </Card>
  );
}