import React from "react";
import Demo from "./Demo";
import LandingPage from "./LandingPage";
import Admin from "./Admin";
import Kitchen from "./Kitchen";
import Menu from "./MenuPDF";
import DevTool from "./DevTool";
import Restaurant from "./RestaurantDashboard/";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/demo/*" component={Demo} />
        <Route path="/admin/" component={Admin} />
        <Route path="/kitchen/" component={Kitchen} />
        <Route path="/menu/" component={Menu} />
        <Route path="/dev-tool/" component={DevTool} />
        <Route path="/restaurant/" component={Restaurant} />
        <Route path="/*" component={LandingPage} />
      </Switch>
    </Router>
  );
}