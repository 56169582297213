import React, { useEffect, useState } from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { CovidForm } from '../components/CovidForm';
import CovidInfo from '../components/CovidInfo';
import api from "../../api";
import LoadingPage from './LoadingPage';

export default function CovidContactPage() {
  let [isLoading, setIsLoading] = useState(true);
  let [myContactInfo, setMyContactInfo] = useState(null);

  useEffect(() => {
    api.covid.getMyContactInfo()
      .then(response => {
        console.log("getMyContactInfo");
        console.log(response);
        setIsLoading(false);
        setMyContactInfo(response.data);
      })
      .catch(error => {
        console.log(error);
        setMyContactInfo(null);
        setIsLoading(false);
      });

  }, []);

  let contactInfoCallback = (contactInfo) => {
    setMyContactInfo(contactInfo);
  }

  return (
    <Container maxWidth="sm" height="100%">
      { 
      isLoading ? <LoadingPage/> : 
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "20px", height: "100%" }}>
        {
          (!myContactInfo) ? <CovidForm contactInfoCallback={contactInfoCallback} /> : <CovidInfo info={myContactInfo} />
        }
      </Box>
      }
    </Container>
  );
}