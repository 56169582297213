import React from 'react';
import Button from "@material-ui/core/Button";
import { useHistory } from 'react-router-dom';
import Cookies from "js-cookie";

export default function LogoutButton() {
  const history = useHistory();
  let onLogout = (event) => {
    Cookies.remove('user');
    history.push("/owner-tool/login");
  }

  return (
    <Button variant="contained" onClick={onLogout}>
      Log out
    </Button>
  );
}