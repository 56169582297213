import React from "react";
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import { Box, Tabs, Tab, makeStyles } from "@material-ui/core";

export default function RestaurantFooter({value}) {

  return (
    <AppBar color="inherit" position="relative" style={{position:"fixed", bottom:0}}>
      <Tabs value={value} aria-label="tabs" variant="fullWidth" width="100vw" styles={{top: "0px"}}>
        <Tab label="Contact Form" component={Link} to={`/menu/covid/`} style={{ paddingTop: "15px", paddingBottom: "15px" }} />
        <Tab label="Menu" component={Link} to={`/menu/menu`} style={{ paddingTop: "15px", paddingBottom: "15px" }} />
      </Tabs>
    </AppBar>
  );
}