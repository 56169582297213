import React, { useState, useEffect } from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api';

const TableNamePageStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  greyText: { color: "grey" },
  link: { color: "#9534F7", textDecoration:"none", fontWeight:400, fontSize:18, padding:"10px" }

}));


export default function SetTableName() {
  let [newTableName, setNewTableName] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  let [qrCode, setQRCode] = useState(null);
  let [isButtonLoading, setIsButtonLoading] = useState(false);
  const classes = TableNamePageStyles();
  const { alias } = useParams();

  const onTableNameChanged = (e) => {
    setNewTableName(e.target.value);
  };

  const onSetTableName = (e) => {
    //Check to make sure QR code exists
    setIsButtonLoading(true);
    if (!qrCode) {
      console.log("QR Code is null");
      return;
    }
    console.log(newTableName);
    api.qrCode.setTableName(qrCode._id, newTableName)
      .then(response => {
        console.log(response);
        setQRCode(response.data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsButtonLoading(false);
      })
  };

  // Init 
  useEffect(() => {
    api.qrCode.getByAlias(alias)
      .then(response => {
        console.log(response.data);
        // qrCode = response.data;
        setIsLoading(false);
        setQRCode(response.data);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  }, [alias])


  return (
    (isLoading) ? "Loading..." :
      !qrCode ? "This QR code doesn't exist!" :
        <Container maxWidth="sm" style={{ textAlign: 'center', minHeight: "100vh", display: "flex", flexDirection: "column" }} >
          <Box style={{ flexGrow: 1 }} />
          <Card>
            <CardContent >
              <Box padding="15px">

                <Typography variant="h5" component="h3" >Name Table</Typography>
                <br />
                <Typography className={classes.desc} >Current Table Name: {qrCode.tableName}</Typography>

                <form className={classes.root} noValidate >

                  <TextField
                    label="Table Name"
                    margin="normal"
                    fullWidth
                    placeholder="Table Name"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="outlined"
                    type="email"
                    required={true}
                    onChange={onTableNameChanged}
                  />


                  <Button onClick={onSetTableName} disabled={isButtonLoading} variant="contained" color="primary" style={{ marginTop: 15 }}>
                    {isButtonLoading ? "Loading..." : "Update Name"}
                  </Button>

                  <br /><br />

                  <Typography className={classes.desc} >QR code alias: {alias}</Typography>
                  <Typography className={classes.desc} >Table Name: {qrCode.tableName}</Typography>

                </form>
                <Box>
                  <Link to={`/admin/change-restaurant/${alias}`} className={classes.link}>Change Restaurant</Link>
                  <br /><br />
                  <Link to={`/menu/`} className={classes.link} >View Menu</Link>
                </Box>
                <br />
                {/* <Typography style={{ padding: "15px", color: "red" }}>{errorMessage}</Typography> */}
              </Box>
            </CardContent>
          </Card>
          <Box style={{ flexGrow: 1 }} />
        </Container>
  );
}