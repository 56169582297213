
import React from 'react';
import GridListTile from '@material-ui/core/GridListTile';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import { Box, Typography } from '@material-ui/core';


export default function OrderCard({ past=false, order, isSelected = false, onSelected=()=>{console.log("selected:", order)}, onUnselected=()=>{console.log("unselected:", order)}}) {
  const classes = useStyles();
  const toggleSelected = () => {
    if (isSelected) {
      onUnselected(order);
    }
    else {
      onSelected(order);
    }
  };

  return (
    !order ? "no order" :
      <GridListTile key={order._id} className={classes.tileGrid}>
        <Card variant="outlined" className={isSelected ? classes.card_selected : classes.card} onClick={toggleSelected} >
          <CardContent>
            <Box style={{display:"flex", flexDirection:"row"}}> 
              <Typography className={classes.title}>
                Table {order.tableName} - {order.user} 
              </Typography>
              <Box style={{flexGrow: 1}} />
              {!past ? "": (<HistoryIcon className={classes.historyIcon}/>)} 
            </Box>

            <div className={classes.subtitle}>  {order.order} </div>
            {order.notes ? <div className={classes.notes}> Notes: {order.notes} </div> : ""}
          </CardContent>
          <div className={classes.timestamp}>{order.time} </div>
        </Card>
      </GridListTile>
  );
}

const useStyles = makeStyles((theme) => ({

  card_selected: {
    border: '5px solid',
    borderColor: '#9534F7',
  },
  card: {
    border: '5px solid',
    borderColor: 'white',
  },
  title: {
    fontSize: '23px',
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: '23px',
    fontWeight: 'bold',
    color: 'gray'
  },
  notes: {
    paddingTop: theme.spacing(1),
    fontSize: '18px',
    color: 'gray'
  },
  timestamp: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 260,
    fontSize: '20px',
    color: 'gray'
  },
  tileGrid: {
    padding: 20,
    width:"100%"
  }
}));