import React from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button, Container } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/Camera';
import LogoutButton from '../components/LogoutButton';

export default function HomePage() {

  return (
    <Container maxWidth="sm" height="100%" style={{ textAlign: 'center' }}>

      <Box padding="15px">
        <Typography variant="h5" component="h2" >Logged In</Typography>
        <Typography>
          Scan QR code with your camera to set table names.
        </Typography>
        <br />
        <a href={"/api/csv-covid"} target="_blank" rel="noopener noreferrer" download style={{textDecoration:"none"}}>
          <Button variant="contained" >
            {/* <i className="fas fa-download" /> */}
              Download Covid-19 CSV
          </Button>
        </a>
        <br />
        <br />
        <CameraIcon style={{ color: 'grey', height: 160, width: 160 }} />
        <br />
        <br />
        <LogoutButton />
      </Box>

    </Container>
  );
}