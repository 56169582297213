import React, { useState } from 'react';
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import api from "../../api";


// axios.defaults.withCredentials = true;
// let apiUrl = "http://192.168.1.167:9080/api";

const OwnerLoginCardStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    // height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
}));

function LoginCard() {
  const classes = OwnerLoginCardStyles();
  const history = useHistory();

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [llog, setLog] = useState("");
  let [loading, setLoading] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");

  const onEmailChanged = (e) => {
    setEmail(e.target.value);
  };
  
  const onPasswordChanged = (e) => {
    setPassword(e.target.value);
  };

  const onLogin = () => {
    setLoading(true);
    api.auth.restaurantLogin(email, password)
      .then(response => {
        console.log(response);
        console.log(response.data);
        console.log("cookie: " + document.cookie);
        setLog(JSON.stringify(response.data));
        setErrorMessage("");
        history.push("/restaurant/");
      })
      .catch(error => {
        // error.response.data is suppose to contain info about our error from our server, but it does not.
        console.log(error.response);
        setErrorMessage("Failed to login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container maxWidth="sm" height="100%">
      <Card>
        <CardContent >
          <Box padding="15px">

            {/* For debug purposes lol */}
            <Typography style={{ padding: "15px" }}>
              {llog}
            </Typography>

            <Typography variant="h5" component="h3" >Restaurant Login</Typography>
            <form className={classes.root} noValidate >

              <TextField
                label="Email"
                margin="normal"
                fullWidth
                placeholder="Email"
                InputLabelProps={{ shrink: true }}
                size="small"
                variant="outlined"
                type="email"
                name="email"
                autocomplete="email"
                required={true}
                onChange={onEmailChanged}
              />

              <TextField
                label="Password"
                fullWidth
                placeholder="Password"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                variant="outlined"
                type="password"
                name="password"
                autocomplete="password"
                required={true}
                onChange={onPasswordChanged}
              />
              <Button onClick={onLogin} disabled={loading} variant="contained" color="primary" style={{ marginTop: 15 }}>
                {loading ? "Loading..." : "Login"}
              </Button>
            </form>
            <br />
            <Typography style={{ padding: "15px", color: "red" }}>{errorMessage}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function LoginPage() {
  return (
    <Box>
      {LoginCard()}
    </Box>
  );
}