import React, {useEffect} from "react";
import { CssBaseline, Box, Typography } from '@material-ui/core';
import RestaurantPage from "./pages/"
import { Switch, Route } from "react-router-dom";
import api from "../api";

export default function Menu() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [restaurant, setRestaurant] = React.useState(null);

  useEffect(()=>{

    // Your axios call here
    api.qrCode.getScannedRestaurant()
    .then(response => {
      console.log(response);
      // For success, update state like
      if (response.data) {
        console.log(response.data.json);
        setIsLoading(false);
        setRestaurant(response.data.json);
      }
      else {
        setIsLoading(false);
        setRestaurant(null);
      }
    })
    .catch(error => {
      console.log(error);
      setRestaurant(null);
      setIsLoading(false);
    });
  }, [])
  

  return (
    isLoading ? "Loading..." :
      restaurant ?
        <Box>
          <CssBaseline />
          <Switch>
            <Route path="/menu/">
              <RestaurantPage restaurant={restaurant}/>
            </Route>
          </Switch>
        </Box>

        :

        <Typography>
          Scan a QR-Code to view a restaurant's menu
        </Typography>
  )
}
