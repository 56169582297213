export default [
  {
    name: "Tiramisu",
    price: "$8",
    description: "Homemade Tiramisu' with eggs, sugar, mascarpone, laid on a bed of savoiardi dipped in espresso coffee."
  },
  {
    name: "Crème Brûlée",
    price: "$8",
    description: "Homemade Crème Brûlée' made with eggs, cream, sugar. (Ask for the flavor of the  day)"
  },
  {
    name: "Torta Al Cioccolato",
    price: "$8",
    description: "Layers of chocolate cake made with cocoa, filled  wih  chocolate and hazelnut cream, covered with chocolate glaze."
  },
  {
    name: "Panna Cotta",
    price: "$8",
    description: "Italian custard and mixed  berry coulis."
  },
  {
    name: "Pastiera Napoletana",
    price: "$8",
    description: "The classic Napolitan cake: short crust pastry base, filled with a blend of ricotta cheese, wheat and candied fruit."
  },
  {
    name: "Lemon Blueberry Cheesecake",
    price: "$8",
    description: "A creamy lemon cheesecake topped with blueberries and sugar crumbs, siting on a cookie crumb base."
  },
  {
    name: "Tartufo Al Cioccolato",
    price: "$8",
    description: "Chocolate gelato with a zabaione cream and hazelnut centre, topped with cocoa powder."
  },
  {
    name: "Tartufo Al Limoncello",
    price: "$8",
    description: "Lemon gelato with a heart of limoncello covered in merinque."
  },
  {
    name: "Sorbetto Al Limone",
    price: "$8",
    description: "Refreshing lemon sorbetto served in the natural fruit shell."
  },
]

