import React from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { CssBaseline } from '@material-ui/core';
import { NavBar } from './NavBar';

const url = 'https://www.gannett-cdn.com/presto/2020/02/17/USAT/80fa5afd-dd88-407d-aad1-9e25f8ad3b47-04_HOUSTON_Coronavirus_restaurants_021.JPG';

export default function DevTools() {

  return (
    // <Container style={{padding:"0px"}}>

    <Box height="100vh" width="100%" style={{ background: "url(" + url + ")", backgroundSize: "cover" }}>
      <CssBaseline />
      <NavBar />
      <Container maxWidth="sm">
        <Box height="40vh" />
        <br />
        <a href="http://localhost:8000/api/qr-code/scan/1jd3">
          <Button variant="contained" color="primary">
            localhost:8000 QR Code Scan
          </Button>
        </a>

        <a href="http://localhost:3000/menu">
          <Button variant="contained" color="primary">
            localhost:3000 menu
          </Button>
        </a>
        <Box height="10vh" />
      </Container>
    </Box>
    // </Container>
  );
}