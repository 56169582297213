import vinirossi from "./la-cucina/ViniRossi.js";
import desserts from "./la-cucina/dessert.js";

// Page 1
let antipasti = [
  {
    name: "Zuppa Del Giorno",
    price: "$8.50",
    description: "Please ask your server for the soup of the day."
  },
  {
    name: "Bruschette Al Pomodoro",
    price: "$9",
    description: "Toasted sliced  bread topped with chopped tomato with  garlic, basil and extra v. olive oil"
  },
  {
    name: "Arancini Di Riso",
    price: "$10",
    description: "Homemade rice balls stuffed with Bolognese ragu', sweet peas, and mozzarella"
  },
  {
    name: "Bocconcini Di Pizza",
    price: "$12",
    description: "Pizza bites folded and filled wih ricotta, soppressata, ham, and fior di latte mozzarella."
  },
  {
    name: "Calamari Fritti",
    price: "$14",
    description: "Deep fried lightly floured Atlantic calamari."
  },
  {
    name: "Parmigiana Di Melanzane",
    price: "$14",
    description: "Layers of fried eggplant, fresh mozzarella cheese, basil, Parmigiano Reggiano, tomato sauce."
  },
  {
    name: "Salami E Formaggi",
    prices: [{ price: "$15", note: "For One" }, { price: "$25", note: "For Two" }],
    description: "Classic salami and cheese board with Parma prosciutto, cacciatorino salami, hot calabrese salami, homemade porchetta, parmiggiano cheese and olives."
  },
  {
    name: "Polpettine Della Nonna",
    price: "$10",
    description: "Homemade meatballs with beef, pork and veal, served in a marinara sauce."
  },
  {
    name: "Gamberi Alla Diavola",
    price: "$16",
    description: "Tiger shrimps sautéed in spicy tomato sauce served with deep fried bread."
  },
  {
    name: "Burrata",
    image: require("../burrata.png"),
    prices: [{ price: "$11", note: "Half" }, { price: "$18", note: "Full" }],
    description: "Fresh Italian buffalo mozzarella with a luxurious creamy center, served on a bed of arugula and crostini."
  },
]
let insalate = [
  {
    name: "Classica",
    price: "$9",
    description: "Mixed green salad, cherry tomato, Italian dressing."
  },
  {
    name: "Cesare",
    price: "$10",
    description: "Romaine lettuce, shaved parmigiano, croutons, homemade Caesar dressing."
  },
  {
    name: "Arugulae E Grana",
    price: "$13",
    description: "Fresh arugula salad, shaved grana, cherry tomato, citronette dressing and balsamic reduction."
  },
  {
    name: "Cavolo & Arancio",
    price: "$12",
    description: "Black Kale, roasted barley, sliced orange. Orange citronette dressing."
  },
  {
    name: "Caprese Salad",
    price: "$15",
    description: "Fresh buffalo mozzarella, tomatoes, basil, drizzled with extra v. olive oil and balsamic reduction."
  },
]
// End of page 1
// Page 2
let freshHomemadePasta = [
  {
    name: "Pasta Al Pomodoro",
    price: "$15",
    description: "Your choice of homemade Tagliatelle or rigatoni served in a S. Marzano tomato sauce."
  },
  {
    name: "Lasagne",
    price: "$19.75",
    description: "Traditional Bolognese sauce  between layers of fresh pasta and  mozzarella, with  Parmiggiano, Reggiano and bechamel sauce."
  },
  {
    name: "Cannelloni",
    price: "$19",
    description: "Homemade cannelloni filled  with fresh ricotta and spinach with S. Marzano tomato sauce."
  },
  {
    name: "Tagliatelle Bolognese",
    price: "$19",
    description: "Homemade Fettuccine served with a traditional Bolognese ragu' sauce."
  },
  {
    name: "Raviolini Al Radicchio",
    price: "$19.50",
    description: "Homemade Raviolini filled with chicken and cheese served with fresh Italian sausage and Radicchio."
  },
  {
    name: "Fini Fini Ai Gamberi",
    price: "$22.50",
    description: "Homemade Spaghettini with tiger shrimps in a cherry tomato and white wine sauce."
  },
  {
    name: "Gnocchi Ai 4 Formaggi",
    price: "$19.50",
    description: "Homemade potato gnocchi tossed in a four Italian cheese creme sauce."
  },
  {
    name: "Oreccheitte Alle Cime Di Rape",
    titleNotes: ["Vegetarian"],
    price: "$17.50",
    description: "Homemade penne with Italian Pancetta in a rose 'vodka cream sauce."
  },
  {
    name: "Penne Alla Vodka",
    price: "$18.50",
    description: "Homemade Penne with Italian Pancetta in a rose 'vodka cream sauce."
  },
]
let secondiSfiziosi = [
  {
    name: "Veal Parmiggiana",
    price: "$23",
    description: "Breaded veal cutlet in a marinara sauce served with side fettuccine in a tomato sauce."
  },
  {
    name: "Scaloppine Al Limone",
    price: "$23",
    description: "Pork tenderloin scaloppini in velvety lemon sauce, served with roasted potato."
  },
  {
    name: "Agnello Alla Griglia",
    price: "$30",
    description: "Ontario Lamb chop marinated and grilled served with roasted potato."
  },
  {
    name: "Pescato Del Giorno",
    priceNote: "$ Market Price",
    description: "Daily fresh market fish, based on availability."
  },
]
// End of page 2

/*
 Potential typo's ? souteed => sautéed, 
 also inconsistent use of extra v. olive oil 
 or extra virgin olive oil.
*/
// Page 3
let authenticWoodFiredPizzas = [
  {
    name: "Margherita",
    price: "$14.50",
    description: "San Marzano tomato, fresh fior di latte mozzarella, basil, extra v. olive oil."
  },
  {
    name: "Marinara",
    price: "$13",
    description: "San Marzano tomato, oregano, garlic, extra v. olive oil."
  },
  {
    name: "Napoletana",
    price: "$15",
    description: "San Marzano tomato, anchovies, capers, black olives, basil, extra v. olive oil."
  },
  {
    name: "Scarpariello",
    price: "$16",
    description: "San Marzano tomato, Garlic, Chilly flakes, Parmigiano Reggiano, extra virgin olive oil."
  },
  {
    name: "Cotto E Funghi",
    price: "$16.50",
    description: "San Marzano tomato, cooked ham souteed mushrooms basil, extra virgin olive oil."
  },
  {
    name: "Bufalina",
    price: "$17.50",
    description: "San Marzano tomato, fresh buffalo mozzarella, basil, extra v. olive oil."
  },
  {
    name: "Capricciosa",
    price: "$17",
    description: "San Marzano tomato, fresh fior di latte mozzarella, mushrooms, artichokes, black olives and ham."
  },
  {
    name: "Caprese",
    price: "$18",
    description: "White pizza with fresh buffalo mozzarella, cherry tomato, fresh basil, extra v. olive oil."
  },
  {
    name: "Quattro Formaggi",
    price: "$16.50",
    description: "White pizza  with fresh fior di latte, gorgonzola, fontina, asiago and  parmigiano reggiano."
  },
  {
    name: "Diavolina Calabrese",
    price: "$17.50",
    description: "San Marzano tomato, fresh fior di latte mozzarella, roasted red pepper, hot calabrese salame, and La Cucina piccante."
  },

  // End of page 3
  // Page 4
  {
    name: "Montanara",
    price: "$16.50",
    description: "White pizza with fresh fior di latte mozzarella, Italian sausage and sautéed rapini."
  },
  {
    name: "Abruzzese",
    price: "$17",
    description: "San Marzano tomato, fresh fior di latte mozzarella, abruzzese salame and mushrooms."
  },
  {
    name: "Rustica",
    price: "$18",
    description: "White pizza, with fresh fior di latte mozzarella, goat cheese, hot Calabrese salame and La Cucina piccante."
  },
  {
    name: "Genovese",
    price: "$18.50",
    description: "White pizza with fresh fior di latte mozzarella, Basil pesto, roasted  red pepper, black olives, mushrooms and fresh basil."
  },
  {
    name: "Speck E Gorgonzola",
    price: "$18",
    description: "San Marzano tomato, fresh fior di latte mozzarella, gorgonzola cheese, finished with speck."
  },
  {
    name: "Al Pollo",
    price: "$17",
    description: "San Marzano tomato, fresh fior di latte mozzarella, grilled chicken, roasted red pepper, parmiggiano."
  },
  {
    name: "Parma",
    price: "$18",
    description: "White pizza with fresh fior di latte mozzarella, cherry tomato, arugula and Parma prosciutto."
  },
  {
    name: "Vegetariana",
    price: "$16",
    description: "San Marzano tomato, fresh fior  di latte mozzarella, grilled eggplant, zucchini, roasted red pepper and mushrooms."
  },
  {
    name: "Calzone Classico",
    price: "$18",
    description: "Folded pizza, with fresh fior di latte mozzarella, ricotta cheese, mushrooms, ham topped with San Marzano tomato."
  },
  {
    name: "Calzone Rustic",
    image: require("../calzone.png"),
    price: "$18",
    description: "Folded pizza with fresh fior di latte mozzarella, rapini, Italian sausage, zucchine, and red onion."
  },
]

let mainMenu = {
  name: "Menu",
  categories: [
    {name: "Antipasti", menuItems: antipasti},
    {name: "Insalate", menuItems: insalate},
    {name: "Fresh Home Made Pasta", menuItems: freshHomemadePasta},
    {name: "Secondi Sfiziosi", menuItems: secondiSfiziosi},
    {name: "Authentic Wood Fired Pizzas", menuItems: authenticWoodFiredPizzas},
  ]
}


let drinksMenu = {
  name: "Drinks",
  categories: [
    // {name: "wines", menuItems: antipasti},
    // {name: "beers", menuItems: antipasti},
    {name: "Vini Rossi", menuItems: vinirossi}
  ]
}

let desertMenu = {
  name: "Desserts",
  categories: [
    {name: " ", menuItems: desserts}
  ]
}

export default {
  name: "La Cunina",
  subTitle: "Ristorante - Pizzeria - Wine Bar",
  menus: [mainMenu, drinksMenu, desertMenu]
}