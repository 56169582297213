import React, { useEffect, useState } from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { CovidForm } from '../components/CovidForm';
import CovidInfo from '../components/CovidInfo';
import api from "../../api";
import LoadingPage from './LoadingPage';
import RestaurantHeader from "../components/RestaurantHeader";
import RestaurantNavBar from "../components/RestaurantFooter";
import { AppBar } from '@material-ui/core';

export default function CovidContactPage({ restaurant }) {
  let [isLoading, setIsLoading] = useState(true);
  let [myContactInfo, setMyContactInfo] = useState(null);

  useEffect(() => {
    api.covid.getMyContactInfo()
      .then(response => {
        console.log("getMyContactInfo");
        console.log(response);
        setIsLoading(false);
        setMyContactInfo(response.data);
      })
      .catch(error => {
        console.log(error);
        setMyContactInfo(null);
        setIsLoading(false);
      });

  }, []);

  let contactInfoCallback = (contactInfo) => {
    setMyContactInfo(contactInfo);
  }

  return (


    <Box style={{ display: "flex", flexDirection: "column", flexGrow:1}}>
      <AppBar elevation={1} position="sticky" color="white" variant="fullWidth">
        <RestaurantHeader restaurant={restaurant} value={0} />
      </AppBar>

      <Container style={{ padding: "0px", overflow: "scroll", flexGrow:1, display:"flex", flexDirection:"column", justifyContent: 'center' }} maxWidth="sm">
        {
          isLoading ? <LoadingPage /> :
            // <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "20px", marginBottom: "20px", height: "100%" }}>
            <Box style={{ display: 'flex', flexDirection:"column", alignItems: 'center', justifyContent: 'center', height: "100%" }}>
              {(!myContactInfo) ? <CovidForm contactInfoCallback={contactInfoCallback} /> : <CovidInfo info={myContactInfo} />}
            </Box>
        }
      </Container>

    </Box>
  );
}