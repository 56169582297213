import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

export default function OrderItem({ orderedItem }) {
  const { name, priceInfo, confirmed } = orderedItem;
  const confirmedMessage = "Kitchen has confirmed order.";
  const pendingMessage = "Waiting for kitchen to confirm order.";
  const classes = useStyles();

  return (

    <Box className={classes.tile}>
      <Box className={classes.details} style={{ marginLeft: "20px" }}>
        <Typography style={{ fontSize: 16, color: "#1E2B37", fontWeight: 500, lineHeight: "16px" }}>
          {name}
        </Typography>

        <Typography style={{ fontSize: 14, color: "#1E2B37", fontWeight: 400, lineHeight: "14px", marginTop: "5px", marginBottom: "5px" }}>
          {priceInfo}
        </Typography>
      </Box>
      <div className={classes.flex} />

      <Box className={classes.details}>
        {confirmed ? confirmedMessage : pendingMessage}
      </Box>
    </Box>

  );
}

const useStyles = makeStyles((theme) => ({

  tile: {
    display: 'flex',
    flexDirection: 'row',
    height: "80px",
    backgroundColor: "white",
    borderBottom: '1px solid #DEE3E9'

  },

  details: {
    margin: "auto",
    marginRight: "20px",
  },

  flex: {
    flex: '1 0 auto',
    marginTop: "auto"
  },

}));