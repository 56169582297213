import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LogoutButton from "./LogoutButton";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  menuButton: { marginRight: theme.spacing(2) },
  title: { flexGrow: 1 },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white"
  },
}));

export default function DashboardNavBar() {
  const classes = useStyles();
  return [
    <AppBar elevation={2} position="sticky" className={classes.appBar}>
      <Toolbar>
        <Link to="/" >
          <img src="/get-the-menu.png" alt="Get The Menu" style={{ maxHeight: "50px", paddingTop: "5px" }} />
        </Link>
        <div style={{ flexGrow: 1 }}></div>
        <LogoutButton />
        {/* <Button  component={Link} to="/dashboard/contact-us" variant="contained" color="#fff"> Contact Us</Button> */}
      </Toolbar>
    </AppBar>
  ];

}

